import React, { Component } from "react";

export default class Contact extends Component {
  render() {
    return (
      <div className="contact-section" id="Contact">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">Contact Me</h2>
          </div>
        
                <div><span className="email-contact">You can reach me via the form below or by emailing me directly at <a href="mailto:caplanpatricia7@gmail.com">caplanpatricia7@gmail.com</a>.</span> <br/><br/></div>
                <form
                    action={`https://formspree.io/f/mbjvzzzq`}
                    name="contact"
                    method="POST"
                    data-netlify="true"
                >
                    <div>
                        <label>
                            Your Name: <input type="text" name="name" required />
                        </label>
                    </div>
                    <div>
                        <label>
                            Your Email: <input type="email" name="email" required />
                        </label>
                    </div>
                    <div>
                        <label>
                            Message: <textarea name="message" required></textarea>
                        </label>
                    </div>
                    <div>
                        <button type="submit">Send</button>
                    </div>
                </form>
        </div>
      </div>
    );
  }
}
